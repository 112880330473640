<template>
    <div :class="isMobile ? 'mobile-header' : 'header'">
      <logout v-if="isMobile" @click="logout()" :class="hideLogout ? 'logout-hidden' : 'logout'"></logout>
      <div :class="isMobile ? 'logo-mobile' : 'logo-desktop'">
        <logo class="logo" />
        <div class="header-text">
          <span style="font-family: VWHead Bold;">Secure </span>
          <span style="font-family: VWText Light;">Testing</span>
        </div>
      </div>
         <div class="header-right">
      <div v-if="!isMobile && currentRoute !== 'welcome' && currentRoute !== 'login'" style="font-size: 14px; align-self: center; color: white; display: flex">
        Welcome,
        <span style="color: #00B0F0; font-size: 14px; font-family: VWHead Bold; margin-left: 2px;">{{ user.first_name }} {{ user.last_name }}</span>
        <span style="margin: 0 10px;">|</span>
        <a href="https://vwgoalogin.track360.com/logout.php" style="color: #00B0F0; font-size: 14px; font-family: VWHead Bold; cursor:pointer; text-decoration: none;">Log Out</a>
      </div>
    </div>
    </div>
</template>

<script>
import logo from '@/assets/icons/logo.svg';
import logout from '@/assets/icons/logout.svg';

export default {
  name: 'testerHeaderw',
  components: {
    logo,
    logout,
  },
  data() {
    return {
    };
  },
  methods: {
  },
  computed: {
    user() {
      if (this.$store.state.user) {
        return this.$store.state.user;
      }
      return null;
    },
    isMobile() {
      if (this.$mq === 'mobile') {
        return true;
      }
      return false;
    },
    currentRoute() {
      return this.$route.name;
    },
    hideLogout() {
      if (this.$route.name === 'welcome' || this.$route.name === 'testerAssessment') {
        return true;
      }
      return false;
    },
  },
  created() {
  },
};
</script>

<style lang="scss" scoped>
.main {
  margin: 0px;
}

.logo {
  width: 75px;
  margin: 0;
}

.logout {
  width: 26px;
  margin: 0;
  cursor: pointer;
}

.logout-hidden{
  width: 26px;
  height: 0px;
  margin: 0;
}

.header {
  background-color: #001E50;
  padding: 20px 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.mobile-header {
  background-color: #001E50;
  padding: 20px 20px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.logo-desktop {
  display: flex;
  align-items: center;
}

.logo-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: -26px;
  .header-text {
    margin-left: 0px;
    font-size: 20px;
  }
  .logo {
    width: 60px;
  }
}

.header-text{
  font-size: 28px;
  color: white;
  margin-left: 30px;
}
.title-container {
  display: flex;
  padding: 35px 60px;
  font-size: 20px;
  background-color: #FFFFFF;
  align-items: center;
  border-bottom: 1px solid #DFE4E8;

}
.panel {
  padding: 30px 200px;
  .panel-label {
  font-size: 12px;
  margin-bottom: 15px;
  }
}
.last {
  margin-bottom: 0px !important;
}

</style>
