<template>
  <div class="dashboard" v-if="loginInfo && loginInfo.app_role_id !== 1">
    <div class="main">
      <div class="table-header">
        <div style="font-size: 20px; color: black;"> Recent Assessments
        </div>
        <div style="display: flex;">
          <button style="margin-right: 10px;" @click='navigate("create")'>Create Assessment</button>
          <button  class="alt-btn" @click='navigate("assessments")'>View All</button>
        </div>
      </div>
      <div class="table-container" style='margin-bottom: 40px;'>
        <data-table
        :headings="recentAssessmentsTableHeaders"
        :rows="recentAssessments"
        :perPage="rowCount"
        :page="raPageNumber"
        >
        <template v-slot:actionsSlot="slotProps">
          <div v-if="slotProps.row.status === '1' && !slotProps.row.expired" class="edit-gray"
          v-tooltip="{
              content: 'In order to edit an assessment, the status must be set to Inactive.',
              }">Edit</div>
          <div v-if="slotProps.row.status === '0' || slotProps.row.expired" class='edit-blue'  @click.stop="editRow(slotProps.row)">Edit</div>
          <span style="margin: 0 10px; border-right: 1px solid #B6BFC5"></span>
          <div style="color: #00B0F0; cursor: pointer;" @click.stop="duplicateRow(slotProps.row)">Duplicate</div>
          <span style="margin: 0 10px; border-right: 1px solid #B6BFC5"></span>
          <div v-if="slotProps.row.status === '0' || slotProps.row.expired" style="color: #FF335C; cursor: pointer;" @click.stop="deleteRow(slotProps.row)">Delete</div>
              <div v-if="slotProps.row.status === '1' && !slotProps.row.expired" class="edit-gray" @click.stop="deleteRow(slotProps.row)"
          v-tooltip="{
              content: 'In order to delete an assessment, the status be set to Inactive.',
              }">Delete</div>
          </template>
          <template v-slot:statusSlot="slotProps">
            <toggle-button
            v-if="!slotProps.row.expired"
            style="margin-right: 10px;"
            color= "#00B0F0"
            :value="slotProps.row.status === '1' ? true : false"
            @change="toggleStatus(slotProps.row)"
             v-tooltip="{
              content: slotProps.row.status === '0' ? 'This assessment is currently set to Inactive.': 'This assessment is currently set to Live.',
              }"></toggle-button>
            <span style="font-size: 14px;" v-if="slotProps.row.expired">Expired</span>
            <span style="font-size: 14px;" v-else-if="slotProps.row.status === '1'">Live</span>
            <span style="font-size: 14px;" v-else-if="slotProps.row.status === '0'">Inactive</span>
          </template></data-table>
      </div>
      <div class="table-header">
        <div style="font-size: 20px; color: black;"> Recent Testers
        </div>
        <div>
          <button class='alt-btn' @click='navigate("testers")'>View All</button>
        </div>
      </div>
      <div class="table-container">
         <data-table
        :headings="recentTestersTableHeaders"
        :rows="recentTesters"
        :perPage="rowCount"
        :page="rtPageNumber"
        :clickableRows="true"
        tableName="testers"
        >
         </data-table>
      </div>
    </div>
    <modal class="modal-screens" v-show="isModalOpen">
      <template v-slot: header>
        <div>{{modalHeader}}</div>
      </template>
      <template v-slot:body class="body">
        <div class="app-modal">
          <h3>Are you sure you would like to delete this assessment?</h3>
        </div>
      </template>
      <template v-slot:footer>
        <button @click="commit" style="margin-right: 13px;">YES</button>
        <button @click="closeModal">NO</button>
      </template>
    </modal>
  </div>
</template>

<script>
import moment from 'moment';
import DataTable from '@/components/DataTable.vue';
import Modal from '@/components/Modal.vue';

export default {
  name: 'dashboard',
  components: {
    DataTable,
    Modal,
  },
  data() {
    return {
      recentAssessments: [],
      recentTesters: [],
      rowCount: 5,
      raPageNumber: 1,
      rtPageNumber: 1,
      isModalOpen: false,
      modalHeader: 'CONFIRM',
      selectedRow: null,
    };
  },
  methods: {
    editRow(row) {
      if (row.status !== '1' || row.expired) {
        this.$store.commit('SET_SELECTED_ASSESSMENT', row);
        this.$router.push({
          name: 'edit',
        });
      }
    },
    deleteRow(row) {
      this.selectedRow = row;
      this.isModalOpen = true;
    },
    commit() {
      const loader = this.$loading.show();
      this.$store.dispatch('deleteAssessment', { assessmentID: this.selectedRow.id })
        .then(() => {
          this.$notify({
            text: 'Assessment Deleted Successfully.',
            group: 'primary',
            type: 'success',
          });
          loader.hide();
          this.getRecentAssessments();
          this.isModalOpen = false;
        });
    },
    closeModal() {
      this.isModalOpen = false;
    },
    duplicateRow(row) {
      this.$store.commit('SET_SELECTED_ASSESSMENT', row);
      this.$router.push({
        name: 'duplicate',
      });
    },
    getUser() {
      const loader = this.$loading.show();
      this.$store.dispatch('getUser', { loginInfo: this.loginInfo })
        .then(() => {
          loader.hide();
        });
    },
    getData() {
      if (this.$store.state.assessments.length === 0) {
        this.getRecentAssessments();
      } else if (this.$store.state.testers.length === 0) {
        this.getRecentTesters();
        this.recentAssessments = this.$store.state.recentAssessments;
      } else {
        this.recentAssessments = this.$store.state.recentAssessments;
        this.recentTesters = this.$store.state.recentTesters;
      }
    },
    getRecentAssessments() {
      const loader = this.$loading.show();
      this.$store.dispatch('getAssessments', { assessmentID: '' })
        .then(() => {
          this.recentAssessments = this.$store.state.recentAssessments;
          for (let i = 0; i < this.recentAssessments.length; i += 1) {
            if (this.beforeToday(this.recentAssessments[i].deadline_date)) {
              this.recentAssessments[i].expired = true;
            } else {
              this.recentAssessments[i].expired = false;
            }
          }
          if (this.$store.state.testers.length === 0) {
            loader.hide();
            this.getRecentTesters();
          } else {
            this.recentTesters = this.$store.state.recentTesters;
            loader.hide();
          }
        });
    },
    getRecentTesters() {
      this.$store.dispatch('getTesters').then(() => {
        this.recentTesters = this.$store.state.recentTesters;
      });
    },
    toggleStatus(row) {
      const loader = this.$loading.show();
      const newRow = JSON.parse(JSON.stringify(row));
      if (row.status === '0') {
        // eslint-disable-next-line no-param-reassign
        row.status = '1';
        newRow.status = '1';
        newRow.deadline_date = this.$moment.utc(row.deadline_date).format('MM/DD/YY');
      } else {
        // eslint-disable-next-line no-param-reassign
        row.status = '0';
        newRow.status = '0';
        newRow.deadline_date = this.$moment.utc(row.deadline_date).format('MM/DD/YY');
      }
      this.$store.dispatch('updateAssessment', { assessmentID: row.id, params: newRow })
        .then(() => {
          this.$notify({
            text: 'Status Updated Successfully.',
            group: 'primary',
            type: 'success',
          });
          loader.hide();
        });
    },
    navigate(key) {
      this.$router.push({
        name: key,
      });
    },
    getJobCodes() {
      if (!this.$store.jobCodes) {
        this.$store.dispatch('getJobCodes')
          .then((res) => {
            this.jobCodes = res;
          });
      }
    },
    beforeToday(dateString) {
      const REFERENCE = moment(new Date());
      const TODAY = REFERENCE.clone().startOf('day');

      function isBeforeToday(momentDate) {
        return momentDate.isBefore(TODAY);
      }
      const date = new Date(dateString);
      if (date) {
        if (isBeforeToday(moment(date))) {
          return true;
        }

        return false;
      }
      return dateString;
    },
  },
  computed: {
    recentAssessmentsTableHeaders() {
      return this.$store.state.assessmentsTableHeaders;
    },
    recentTestersTableHeaders() {
      return this.$store.state.testersTableHeaders;
    },
    loginInfo() {
      return this.$store.state.loginInfo;
    },
  },
  watch: {
    loginInfo(val) {
      if (val && val.app_role_id !== 1) {
        this.getData();
        this.getJobCodes();
      }
      if (this.$store.getters.user) {
        this.getUser();
      }
    },
  },
  created() {
    if (this.loginInfo && this.loginInfo.app_role_id !== 1) {
      this.getData();
      this.getJobCodes();
      if (this.$store.getters.user) {
        this.getUser();
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.header-middle {
  display: flex;
}

.table-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-bottom: 25px;
}

.table-container {
  font-size: 26px;
  color: black;
}

.edit-blue {
color: #00B0F0;
cursor: pointer;
}

.edit-gray {
color: #B6BFC5;
cursor: default;
}
</style>
