<template>
  <div class="footer">
   <div>© 2020 Volkswagen Academy</div>
  </div>
</template>

<script>
export default {
};
</script>

<style lang="scss" scoped>
.footer{
    display: flex;
    height: 100%;
    align-items: flex-end;
    justify-content: center;
    font-family: 'VWHead Light';
    font-size: 10px;
    text-align: center;
    padding: 50px 0;
}
</style>
