import Vue from 'vue';
import VueRouter from 'vue-router';
import Dashboard from '../views/Dashboard.vue';
import Login from '../views/login.vue';
import store from '../store/index';

Vue.use(VueRouter);

function requireAuth(to, from, next) {
  if (store.getters.loginInfo) {
    next();
  } else {
    next();
    next({ path: '/' });
  }
}

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/',
    name: 'dashboard',
    component: Dashboard,
    meta: {
      title: 'Remote Testing - Dashboard',
    },
  },
  {
    path: '/assessments',
    name: 'assessments',
    component: () => import(/* webpackChunkName: "assessments" */ '../views/Assessments.vue'),
    beforeEnter: requireAuth,
    meta: {
      title: 'Remote Testing - Assessments',
    },
  },
  {
    path: '/testers',
    name: 'testers',
    component: () => import(/* webpackChunkName: "testers" */ '../views/Testers.vue'),
    beforeEnter: requireAuth,
    meta: {
      title: 'Remote Testing - Testers',
    },
  },
  {
    path: '/create',
    name: 'create',
    component: () => import(/* webpackChunkName: "create" */ '../views/Create.vue'),
    beforeEnter: requireAuth,
    meta: {
      title: 'Remote Testing - Create',
    },
    children: [{
      path: '/create/preview',
      name: 'create-preview',
      component: () => import(/* webpackChunkName: "preview" */ '../views/Preview.vue'),
      meta: {
        requiresAuth: true,
        title: 'Remote Testing - Preview',
      },
    }],
  },
  {
    path: '/results',
    name: 'results',
    component: () => import(/* webpackChunkName: "create" */ '../views/Results.vue'),
    beforeEnter: requireAuth,
    meta: {
      title: 'Remote Testing - Results',
    },
  },
  // {
  //   path: '/preview',
  //   name: 'preview',
  //   component: () => import(/* webpackChunkName: "create" */ '../views/Preview.vue'),
  //   beforeEnter: requireAuth,
  //   meta: {
  //     title: 'Remote Testing - Preview',
  //   },
  // },
  {
    path: '/edit',
    name: 'edit',
    component: () => import(/* webpackChunkName: "create" */ '../views/Edit.vue'),
    beforeEnter: requireAuth,
    meta: {
      title: 'Remote Testing - Edit',
    },
    children: [{
      path: '/edit/preview',
      name: 'edit-preview',
      component: () => import(/* webpackChunkName: "preview" */ '../views/Preview.vue'),
      meta: {
        requiresAuth: true,
        title: 'Remote Testing - Preview',
      },
    }],
  },
  {
    path: '/duplicate',
    name: 'duplicate',
    component: () => import(/* webpackChunkName: "create" */ '../views/Duplicate.vue'),
    beforeEnter: requireAuth,
    meta: {
      title: 'Remote Testing - Duplicate',
    },
    children: [{
      path: '/duplicate/preview',
      name: 'duplicate-preview',
      component: () => import(/* webpackChunkName: "preview" */ '../views/Preview.vue'),
      meta: {
        requiresAuth: true,
        title: 'Remote Testing - Preview',
      },
    }],
  },
  {
    path: '/success',
    name: 'success',
    component: () => import(/* webpackChunkName: "create" */ '../views/Success.vue'),
    beforeEnter: requireAuth,
    meta: {
      title: 'Remote Testing - Success',
    },
  },
  {
    path: '/welcome',
    name: 'welcome',
    beforeEnter: requireAuth,
    component: () => import(/* webpackChunkName: "create" */ '../views/Welcome.vue'),
    meta: {
      title: 'Remote Testing - Welcome',
    },
  },
  {
    path: '/testerDashboard',
    name: 'testerDashboard',
    component: () => import(/* webpackChunkName: "create" */ '../views/TesterDashboard.vue'),
    beforeEnter: requireAuth,
    meta: {
      title: 'Remote Testing - Dashboard',
    },
  },
  {
    path: '/testerAssessment',
    name: 'testerAssessment',
    component: () => import(/* webpackChunkName: "create" */ '../views/TesterAssessment.vue'),
    beforeEnter: requireAuth,
    meta: {
      title: 'Remote Testing - Assessment',
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  // eslint-disable-next-line no-unused-vars
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

export default router;
