<template>
  <div class="data-table">
    <table>
      <thead v-if="headings.length > 0">
        <tr>
          <th
            v-for="head in headings"
            :key="head.key"
            :class="headingClasses(head)"
            @click="sort(head)"
          >
            <span>{{ head.label }}</span>
          </th>
        </tr>
      </thead>
      <tbody v-if="displayRows.length > 0">
        <tr
          v-for="row in displayRows"
          :key="row.id"
          :class="clickableRows ? 'clickable' : null"
          @click="rowSelection(row)"
        >
          <td
            v-for="head in headings"
            :key="head.key"
            :class="[head.key, head.keyClass ? row[head.key] : '']"
          >
            <slot :name="slotName(head)" :row="row" :head="head">
              <img v-if="head.type === 'image'" :src="row[head.key]" />
              <span v-else>{{ filter(row[head.key], head.filter) }}</span>
            </slot>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'DataTable',
  props: {
    // Heading Row. Requires label and key properties
    headings: {
      type: Array,
      default: () => [],
    },
    // Table Row Objects
    // Displayed value is based on the heading row key property.
    rows: {
      type: Array,
      default: () => [],
    },
    // Optional propertly to disable sorting (set to false)
    sortable: {
      type: Boolean,
      default: true,
    },
    // Optional property to enable lazy loading of data
    // Note, it's not real lazy loading...
    lazyLoad: {
      type: Boolean,
      default: false,
    },
    // TODO Pagination
    perPage: {
      type: Number,
      default: 20,
    },
    page: {
      type: Number,
      default: 1,
    },
    searchQuery: {
      type: String,
      default: '',
    },
    tableName: {
      type: String,
      default: '',
    },
    clickableRows: {
      type: Boolean,
      default: false,
    },
    jobFilter: {
      type: String,
      default: '',
    },
    resultsFilters: {
      type: Object,
    },
  },
  data() {
    return {
      sortedKey: null,
      sortDirection: 'asc',
      sortType: 'string',
    };
  },
  computed: {
    displayRows() {
      if (this.rows && this.rows.length > 0) {
        let rows = this.rows.slice(0);
        // Sort all rows
        if (this.sortable && this.sortedKey) {
          rows.sort((a, b) => {
            if (
            // eslint-disable-next-line no-prototype-builtins
              a.hasOwnProperty(this.sortedKey)
            // eslint-disable-next-line no-prototype-builtins
            && b.hasOwnProperty(this.sortedKey)
            && a[this.sortedKey]
            && b[this.sortedKey]
            ) {
              if (this.sortType === 'number') {
                if (this.sortDirection === 'desc') return b[this.sortedKey] - a[this.sortedKey];
                return a[this.sortedKey] - b[this.sortedKey];
              }
              if (this.sortDirection === 'desc') return b[this.sortedKey].localeCompare(a[this.sortedKey]);
              return a[this.sortedKey].localeCompare(b[this.sortedKey]);
            }
            return 0;
          });
        }

        // Filter out rows
        if (this.searchQuery !== '') {
          const query = this.searchQuery.toLowerCase();
          rows = rows.filter((row) => {
          // eslint-disable-next-line no-restricted-syntax
            for (const key in row) {
              if (
              // eslint-disable-next-line no-prototype-builtins
                row.hasOwnProperty(key)
              && row[key]
              && row[key]
                .toString()
                .toLowerCase()
                .indexOf(query) > -1
              ) return true;
            }
            return false;
          });
        }

        if (this.jobFilter !== '') {
          const query = this.jobFilter.toLowerCase();
          rows = rows.filter((row) => {
            // eslint-disable-next-line no-restricted-syntax
            for (const key in row) {
              if (
              // eslint-disable-next-line no-prototype-builtins
                row.hasOwnProperty(key)
              && row[key]
              && (row[key] === row.job_role_name)
              && row[key]
                .toString()
                .toLowerCase()
                .indexOf(query) > -1
              ) return true;
            }
            return false;
          });
        }

        if (this.resultsFilters) {
          if (this.resultsFilters.assessment_name || this.resultsFilters.dealer_code || this.resultsFilters.job_codes) {
            // eslint-disable-next-line func-names
            if (this.resultsFilters.assessment_name) {
              rows = rows.filter(row => (row.assessment_name === this.resultsFilters.assessment_name));
            }
            if (this.resultsFilters.dealer_code) {
              rows = rows.filter(row => (row.dealer_code === this.resultsFilters.dealer_code));
            }
            if (this.resultsFilters.job_codes.length > 0) {
              const jobRows = [];
              for (let i = 0; i < this.resultsFilters.job_codes.length; i += 1) {
                rows.forEach((row) => {
                  if (row.job_code_name === this.resultsFilters.job_codes[i].name) {
                    jobRows.push(row);
                  }
                });
              }
              rows = jobRows;
            }
          }
        }

        if (this.perPage > 0) {
          const end = this.page * this.perPage;
          const start = this.lazyLoad ? 0 : end - this.perPage;
          rows = rows.slice(start, end);
        }
        return rows;
      }
      return [];
    },
    is() {
      return this.$store.getters.isAdmin;
    },
  },
  methods: {
    // Return the class names for each row
    headingClasses(head) {
      const classes = [];
      if (
        (this.sortable
          && head.sortable !== undefined
          && head.sortable === true)
        || (this.sortable && head.sortable === undefined)
        || head.sortable
      ) classes.push('sortable');
      if (this.sortedKey === head.key) classes.push(`sort-${this.sortDirection}`);
      return classes;
    },
    sort(head) {
      if (this.sortedKey === head.key) this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
      else if (head.sortable !== false) {
        this.sortedKey = head.key;
        // eslint-disable-next-line no-prototype-builtins
        this.sortType = head.hasOwnProperty('sortType')
          ? head.sortType
          : 'string';
        // eslint-disable-next-line no-prototype-builtins
        this.sortDirection = head.hasOwnProperty('sortReverse')
          ? 'desc'
          : 'asc';
      }
    },
    filter(value, filter) {
      if (value && filter && this.$options.filters[filter]) {
        return this.$options.filters[filter](value);
      }
      return value;
    },
    handleLazyLoad() {
      const bottomDistance = document.body.scrollHeight - window.innerHeight - window.scrollY;
      if (bottomDistance <= 100) {
        this.currentPage += 1;
      }
    },
    slotName(head) {
      return `${head.key}Slot`;
    },
    rowSelection(row) {
      if (this.tableName && (this.tableName === 'testers' || this.tableName === 'results')) {
        if (this.$route.name !== 'testers') {
          this.$router.push({
            name: 'testers',
          });
        }
        this.$store.commit('SET_SELECTED_TESTER', row);
      }
    },
  },
  created() {
    let key = null;
    let direction = 'asc';
    let type = 'string';
    // eslint-disable-next-line no-restricted-syntax
    for (const head of this.headings) {
      // eslint-disable-next-line no-prototype-builtins
      if (head.hasOwnProperty('defaultSort')) {
        // eslint-disable-next-line prefer-destructuring
        key = head.key;
        // eslint-disable-next-line no-prototype-builtins
        direction = head.hasOwnProperty('sortReverse') ? 'desc' : 'asc';
        // eslint-disable-next-line no-prototype-builtins
        type = head.hasOwnProperty('sortType')
          // eslint-disable-next-line no-prototype-builtins
          ? head.hasOwnProperty('sortType')
          : 'string';
        break;
      }
    }
    if (key) {
      this.sortedKey = key;
      this.sortDirection = direction;
      this.sortType = type;
    }
    this.currentPage = this.page;
    if (this.lazyLoad) {
      window.addEventListener('scroll', this.handleLazyLoad);
    }
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleLazyLoad);
  },
};
</script>

<style lang="scss" scoped>

table {
  width: 100%;
}

th {
  text-align: left;
  white-space: nowrap;
  position: relative;
  letter-spacing: .02em;

  &.sortable {
    cursor: pointer;
    &.sort-asc{
      background-color: #B6BFC5;
      font-family: 'VWHead Bold';
    }
    &.sort-desc {
      background-color: #B6BFC5;
      font-family: 'VWHead Bold';
    }
    &.sort-asc::after,
    &.sort-desc::after {
      background: url('~@/assets/icons/carrot_up.svg?external') center center no-repeat;
      background-size: 16px 8px;
      border: 0 !important;
      margin: -4px 0 0 -8px !important;
      position: absolute !important;
      top: 50% !important;
      right: 20px;
      width: 16px;
      height: 8px;
      vertical-align: middle;
      transition: all 200ms;
      content: '';
      opacity: 1;
    }
    &.sort-desc::after {
      transform: rotate(180deg);
    }
  }
}

.clickable {
  cursor: pointer;
  &:hover {
    background-color: rgba(0,176,240, .1);
    td{
      background-color: transparent;
    }
  }
}

.actions {
  display: flex;
  font-family: "VWText Bold";
  font-size: 14px;
  border-bottom: none;
  border-left: none;
  border-right: none;
}
</style>
