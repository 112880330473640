<template>
  <div id="app">
    <NavBarDesktop
      v-if="stateLoginInfo && showDashboardNav && $route.name !== 'login' && stateLoginInfo.app_role_id !== 1"
    ></NavBarDesktop>
    <div class="admin" v-if="stateLoginInfo">
      <div style="display: flex; flex-direction: column;">
        <Header
          v-if="showDashboardNav && stateLoginInfo && stateLoginInfo.app_role_id !== 1 && $route.name !== 'login'"
        ></Header>
        <TesterHeader
          v-if="(stateLoginInfo && stateLoginInfo.app_role_id === 1) || $route.path === '/login'"
        ></TesterHeader>
        <transition name="fade">
          <router-view />
        </transition>
      </div>
      <Footer></Footer>
      <notifications
        classes="cup-notification"
        group="primary"
        width="100%"
        :position="$mq === 'tablet' ? 'bottom center' : 'bottom left'"
        :duration="8000"
        animation-name="notification"
      />
    </div>
     <div class="admin" v-if="!authToken && !stateLoginInfo">
          <div style="display: flex; flex-direction: column;">

        <TesterHeader
          v-if="(stateLoginInfo && stateLoginInfo.app_role_id === 1) || $route.path === '/login'"
        ></TesterHeader>
       <transition name="fade">
          <router-view />
        </transition>
          </div>
     </div>
  </div>
</template>

<script>
import Vue from 'vue';
import VueMq from 'vue-mq';
import ToggleButton from 'vue-js-toggle-button';
import NavBarDesktop from '@/components/NavBarDesktop.vue';
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import TesterHeader from '@/components/TesterHeader.vue';

Vue.use(ToggleButton);
Vue.use(VueMq, {
  breakpoints: {
    mobile: 700,
    tablet: 900,
    laptop: 1250,
    desktop: Infinity,
  },
});

export default {
  name: 'App',
  components: {
    NavBarDesktop,
    Header,
    Footer,
    TesterHeader,
  },
  data() {
    return {
      loading: this.$loading.show(),
      loginInfo: null,
      authToken: null,
    };
  },
  computed: {
    stateLoginInfo() {
      return this.$store.state.loginInfo;
    },
    showDashboardNav() {
      return this.$store.state.showDashboardNav;
    },
    userInfo() {
      return this.$store.state.user;
    },
  },
  created() {
    // eslint-disable-next-line no-restricted-globals
    if (location.pathname === '/login') {
      this.loading.hide();

      return;
    }
    if (this.$store.state.loginInfo) {
      this.loginInfo = this.$store.state.loginInfo;
      this.authToken = localStorage.getItem('user-token');
      if (this.loginInfo.app_role_id === 1) {
        this.$router.push({ path: '/welcome' });
        this.loading.hide();
      } else {
        this.$router.push({ path: '/' });
        this.loading.hide();
      }
    } else if (this.$route.query['24g_id']) {
      this.$store.dispatch('getLoginInfo', this.$router).then((res) => {
        this.loginInfo = res.data;
        this.authToken = res.token;
        if (this.loginInfo.app_role_id === 1) {
          this.$store.dispatch('getUser', { loginInfo: this.loginInfo })
            .then(() => {
              if (this.userInfo.email) {
                this.$router.push({ name: 'testerDashboard' });
                this.loading.hide();
              } else {
                this.$router.push({ path: '/welcome' });
                this.loading.hide();
              }
            });
        } else {
          this.$router.push({ path: '/' });
          this.loading.hide();
        }
      });
    } else {
      // eslint-disable-next-line no-restricted-globals
      const siteUrl = `${location.protocol}//${location.host}${location.pathname}`;
      window.location = `${process.env.VUE_APP_CALLBACK_URL}/?APP_ID=LEGACY&callback=${siteUrl}`;
    }
  },
};
</script>

<style lang="scss">
@import "~normalize.scss/normalize";
@import "./assets/scss/base.scss";
@import "./assets/fonts/fonts.css";

#app {
  font-family: "VWHead Light", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000000;
  background-color: rgba(223, 228, 232, 0.25);
  display: flex;
  flex-direction: row;
  height: 100%;
  overflow: hidden;
}

.admin {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
  justify-content: space-between;
}

.vue-notification-template.cup-notification {
  color: #fff;
  font-size: 1.4rem;
  font-weight: bold;
  background-color: $primary-dark;
  border-radius: 3px;
  box-shadow: 0 2px 6px rgba($primary-black, 0.16);
  margin: 0 25px 10px;
  padding: 20px 25px;
  max-width: 325px;
  .notification-content {
    display: flex;
    align-items: center;
    img {
      margin-right: 20px;
      max-width: 70px;
      max-height: 70px;
    }
  }
  &.success {
    background-color: $green;
  }
  &.error {
    background-color: $red;
  }
}
.notification {
  &-enter-active,
  &-leave-active {
    transition: transform 1s;
  }
  &-enter,
  &-leave-to {
    transform: translateX(-100%);
  }
  &-move {
    transition: all 0.5s;
  }
}
.fade-enter-active {
  animation: bounce-in 0.8s;
}
.fade-leave-active {
  animation: bounce-in 0.2s reverse;
}
@keyframes bounce-in {
  0% {
    opacity: 0;
  }
  50% {
  }
  100% {
    opacity: 1;
  }
}
</style>
