import axios from 'axios';

export default (url, fileData) => {
  const instance = axios.create();
  const buffer = Buffer.from(fileData.replace(/^data:image\/\w+;base64,/, ''), 'base64');
  instance.defaults.headers['Content-Type'] = '';
  instance.defaults.headers['Content-Encoding'] = 'base64';
  delete instance.defaults.headers.common.Authorization;
  delete instance.defaults.headers.common.auth_type;
  return instance.put(`${url}`, buffer);
};
