/* eslint-disable no-param-reassign */
import Vue from 'vue';
import Vuex from 'vuex';
import ApiService from '@/services/Api';
import CustomApiService from '@/services/CustomApi';
import 'vue-loading-overlay/dist/vue-loading.css';
import S3Api from '../services/S3Api';


Vue.use(Vuex);

const admin = [
  {
    activeIcon: require('../assets/icons/dashboard_active.svg?external'),
    icon: require('@/assets/icons/dashboard_inactive.svg?external'),
    name: 'Dashboard',
    key: 'dashboard',
  },
  {
    activeIcon: require('../assets/icons/assessments.svg?external'),
    icon: require('@/assets/icons/assessments.svg?external'),
    name: 'Assessments',
    key: 'assessments',
  },
  {
    activeIcon: require('../assets/icons/testers_active.svg?external'),
    icon: require('@/assets/icons/testers_inactive.svg?external'),
    name: 'Testers',
    key: 'testers',
  },
  {
    activeIcon: require('../assets/icons/results.svg?external'),
    icon: require('@/assets/icons/results.svg?external'),
    name: 'Results',
    key: 'results',
  },
];

const assessmentsTH = [
  {
    label: 'Assessment Name',
    key: 'name',
    defaultSort: true,
  },
  {
    label: 'Created',
    key: 'created',
    filter: 'date',
    sortType: 'date',
  },
  {
    label: 'Last Edited',
    key: 'updated_at',
    filter: 'date',
    sortType: 'date',
  },
  {
    label: 'Completions',
    key: 'completions',
    sortType: 'number',
  },
  {
    label: 'Actions',
    key: 'actions',
    sortable: false,
  },
  {
    label: 'Status',
    key: 'status',
    sortType: 'number',
  },
];

const testersTH = [
  {
    label: 'First',
    key: 'first_name',
    defaultSort: true,
  },
  {
    label: 'Last',
    key: 'last_name',
  },
  {
    label: 'Hub ID',
    key: 'hub_id',
  },
  {
    label: 'Dealer Code',
    key: 'dealer_code',
  },
  {
    label: 'Email',
    key: 'email',
  },
  {
    label: 'Phone',
    key: 'phone',
    filter: 'phone',
    sortable: false,
  },
  {
    label: 'Job Role',
    key: 'job_role_name',
  },
];
const trackingTH = [
  {
    label: 'Time',
    key: 'time',
    sortable: false,
  },
  {
    label: 'Event Description',
    key: 'event',
  },
];

const resultsTH = [
  {
    label: 'First',
    key: 'first_name',
    defaultSort: true,
  },
  {
    label: 'Last',
    key: 'last_name',
  },
  {
    label: 'Dealer Code',
    key: 'dealer_code',
  },
  {
    label: 'Job Role',
    key: 'job_code_name',
  },
  {
    label: 'Assessment',
    key: 'assessment_name',
  },
  {
    label: 'Result',
    key: 'score',
    sortable: false,
  },
  {
    label: 'Suspicion Level',
    key: 'suspicion',
    sortable: false,
  },
  {
    label: 'Download Results',
    key: 'download',
    sortable: false,
  },
];

const incompleteTH = [
  {
    label: 'Assessment Name',
    key: 'name',
    defaultSort: true,
  },
  {
    label: 'Created',
    key: 'created',
    filter: 'date',
    sortType: 'date',
  },
  {
    label: 'Deadline',
    key: 'deadline_date',
    filter: 'date',
    sortType: 'date',
  },
  {
    label: 'Suggested Time Limit',
    key: 'time_limit',
    sortType: 'number',
  },
  {
    label: 'Actions',
    key: 'actions',
    sortable: false,
  },
];

const mobileIncompleteTH = [
  {
    label: 'Assessment Name',
    key: 'name',
    defaultSort: true,
  },
  {
    label: 'Actions',
    key: 'actions',
    sortable: false,
  },
];

const completedTH = [
  {
    label: 'Assessment Name',
    key: 'name',
    defaultSort: true,
  },
  {
    label: 'Completed',
    key: 'completion_date',
    filter: 'date',
  },
  {
    label: 'Results',
    key: 'score',
    sortable: false,
  },
];

const mobileCompletedTH = [
  {
    label: 'Assessment Name',
    key: 'name',
    defaultSort: true,
  },
  {
    label: 'Results',
    key: 'score',
    sortable: false,
  },
];

const rowCountOptions = [
  {
    label: 'Show 25',
    value: 25,
  },
  {
    label: 'Show 50',
    value: 50,
  },
  {
    label: 'Show 100',
    value: 100,
  },
  {
    label: 'Show 200',
    value: 200,
  },
];

export default new Vuex.Store({
  state: {
    loginInfo: null,
    user: {},
    assessmentsTableHeaders: assessmentsTH,
    testersTableHeaders: testersTH,
    resultsTableHeaders: resultsTH,
    trackingTableHeaders: trackingTH,
    completedTableHeaders: completedTH,
    mobileCompletedTableHeaders: mobileCompletedTH,
    incompleteTableHeaders: incompleteTH,
    mobileIncompleteTableHeaders: mobileIncompleteTH,
    adminMenu: admin,
    testerSelected: null,
    assessmentSelected: null,
    rowOptions: rowCountOptions,
    assessments: [],
    recentAssessments: [],
    testers: [],
    recentTesters: [],
    previousRoute: null,
    auth_type: 'vw',
    jobCodes: [],
    results: [],
    userResults: null,
    securityFeatures: [],
    showDashboardNav: true,
  },
  strict: true,
  mutations: {
    SET_AUTH_TOKEN: (state, data) => {
      localStorage.setItem('user-token', data.token);
      localStorage.setItem('auth_type', 'vw');
      ApiService.init();
    },
    UPDATE_AUTH_TOKEN: (state, token) => {
      if (token) {
        localStorage.setItem('user-token', token);
        ApiService.setToken();
      }
    },
    SET_LOGIN_INFO: (state, loginInfo) => {
      state.loginInfo = loginInfo;
    },
    PURGE_AUTH: (state) => {
      localStorage.removeItem('user-token');
      state.loginInfo = null;
      state.user = {};
    },
    SET_USER: (state, newUser) => {
      state.user = newUser;
    },
    SET_ALL_TESTERS: (state, testers) => {
      state.testers = testers;
    },
    SET_RECENT_TESTERS: (state, testers) => {
      state.recentTesters = testers;
    },
    SET_ALL_ASSESSMENT_DATA: (state, assessments) => {
      state.assessments = assessments;
    },
    SET_RECENT_ASSESSMENT_DATA: (state, assessments) => {
      state.recentAssessments = assessments;
    },
    SET_SELECTED_TESTER: (state, selected) => {
      state.testerSelected = selected;
    },
    SET_SELECTED_ASSESSMENT: (state, selected) => {
      state.assessmentSelected = selected;
    },
    SET_PREVIOUS_ROUTE: (state, route) => {
      state.previousRoute = route;
    },
    SET_JOB_CODES: (state, codes) => {
      state.jobCodes = codes;
    },
    SET_RESULTS: (state, results) => {
      state.results = results;
    },
    SET_USER_RESULTS: (state, userResults) => {
      state.userResults = userResults;
    },
    SET_SECURITY_FEATURES: (state, securityFeatures) => {
      state.securityFeatures = securityFeatures;
    },
    SET_DASHBOARD_NAV: (state, dashboardNav) => {
      state.showDashboardNav = dashboardNav;
    },
  },
  actions: {
    refresh: ({ commit, dispatch }) => new Promise((resolve, reject) => {
      ApiService.post('/refresh')
        .then((res) => {
          commit('SET_AUTH_TOKEN', res.data);
          resolve();
        })
        .catch((error) => {
          if (error.response && (error.response.status === 401 || error.response.status === 403 || error.response.status === 500)) {
            dispatch('noAuth');
          }
          reject(error);
        });
    }),
    getLoginInfo: ({ commit, dispatch }, router) => new Promise((resolve, reject) => {
      ApiService.post('/tokens', router.history.current.query)
        .then((res) => {
          commit('SET_AUTH_TOKEN', res.data);
          commit('SET_LOGIN_INFO', res.data.data);
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response && (error.response.status === 401 || error.response.status === 403 || error.response.status === 500)) {
            dispatch('noAuth');
          }
          reject(error);
        });
    }),
    getCustomLogin: ({ commit, dispatch }, params) => new Promise((resolve, reject) => {
      CustomApiService.init();
      CustomApiService.post('/tokens', params)
        .then((res) => {
          commit('SET_AUTH_TOKEN', res.data);
          commit('SET_LOGIN_INFO', res.data.data);
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response && (error.response.status === 401 || error.response.status === 403 || error.response.status === 500)) {
            dispatch('noAuth');
          }
          reject(error);
        });
    }),
    getUser: ({ commit, dispatch }, { loginInfo }) => new Promise((resolve, reject) => {
      let userId = '';
      if (loginInfo.user_id) {
        userId = loginInfo.user_id;
      } else if (loginInfo.data && loginInfo.data.app_role_id) {
        userId = loginInfo.data.user_id;
      }
      ApiService.get(`/users/${userId}`)
        .then((res) => {
          commit('SET_USER', res.data.data);
          commit('UPDATE_AUTH_TOKEN', res.data.token);
          resolve(res.data.data);
        })
        .catch((error) => {
          if (error.response && (error.response.status === 401 || error.response.status === 403 || error.response.status === 500)) {
            dispatch('noAuth');
          }
          reject(error);
        });
    }),
    getRecentUsers: ({ commit, dispatch }) => new Promise((resolve, reject) => {
      ApiService.get('/users?sortby=last_assessment')
        .then((res) => {
          commit('SET_RECENT_TESTERS', res.data.data);
          commit('UPDATE_AUTH_TOKEN', res.data.token);
          resolve(res.data.data);
        })
        .catch((error) => {
          if (error.response && (error.response.status === 401 || error.response.status === 403 || error.response.status === 500)) {
            dispatch('noAuth');
          }
          reject(error);
        });
    }),
    updateUser: ({ dispatch }, { userId, params }) => new Promise((resolve, reject) => {
      ApiService.patch(`/users/${userId}`, params)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          if (error.response && (error.response.status === 401 || error.response.status === 403 || error.response.status === 500)) {
            dispatch('noAuth');
          }
          reject(error);
        });
    }),
    getTesters: ({ commit, dispatch }) => new Promise((resolve, reject) => {
      ApiService.get('/users?sortby=last_assessment')
        .then((res) => {
          const testers = res.data.data;
          const recentTesters = testers.slice(0, 5);
          commit('SET_RECENT_TESTERS', recentTesters);
          commit('UPDATE_AUTH_TOKEN', res.data.token);
          resolve(res.data.data);
        })
        .catch((error) => {
          if (error.response && (error.response.status === 401 || error.response.status === 403)) {
            dispatch('noAuth');
          }
          reject(error);
        });
    }),
    getAllTesters: ({ commit, dispatch }) => new Promise((resolve, reject) => {
      ApiService.get('/users?role=tester')
        .then((res) => {
          commit('SET_ALL_TESTERS', res.data);
          commit('UPDATE_AUTH_TOKEN', res.data.token);
          resolve(res.data.data);
        })
        .catch((error) => {
          if (error.response && (error.response.status === 401 || error.response.status === 403)) {
            dispatch('noAuth');
          }
          reject(error);
        });
    }),
    getJobCodes: ({ commit, dispatch }) => new Promise((resolve, reject) => {
      ApiService.get('/job_codes')
        .then((res) => {
          commit('SET_JOB_CODES', res.data.data);
          resolve(res.data.data);
        })
        .catch((error) => {
          if (error.response && (error.response.status === 401 || error.response.status === 403 || error.response.status === 500)) {
            dispatch('noAuth');
          }
          reject(error);
        });
    }),
    getSecurityQuestions: ({ dispatch }) => new Promise((resolve, reject) => {
      ApiService.get('/security_questions')
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((error) => {
          if (error.response && (error.response.status === 401 || error.response.status === 403 || error.response.status === 500)) {
            dispatch('noAuth');
          }
          reject(error);
        });
    }),
    getResults: ({ commit, dispatch }) => new Promise((resolve, reject) => {
      ApiService.get('/results')
        .then((res) => {
          commit('SET_RESULTS', res.data.data);
          resolve(res.data.data);
        })
        .catch((error) => {
          if (error.response && (error.response.status === 401 || error.response.status === 403 || error.response.status === 500)) {
            dispatch('noAuth');
          }
          reject(error);
        });
    }),
    getAssessmentByUser: ({ dispatch }, { userId }) => new Promise((resolve, reject) => {
      ApiService.get(`/users/${userId}/assessment_instances`)
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((error) => {
          if (error.response && (error.response.status === 401 || error.response.status === 403 || error.response.status === 500)) {
            dispatch('noAuth');
          }
          reject(error);
        });
    }),
    getUserResults: ({ commit, dispatch }, { userId }) => new Promise((resolve, reject) => {
      ApiService.get(`/users/${userId}/results`)
        .then((res) => {
          commit('SET_USER_RESULTS', res.data.data);
          resolve(res.data.data);
        })
        .catch((error) => {
          if (error.response && (error.response.status === 401 || error.response.status === 403 || error.response.status === 500)) {
            dispatch('noAuth');
          }
          reject(error);
        });
    }),
    getSecurityFlags: ({ commit, dispatch }, { assessmentID }) => new Promise((resolve, reject) => {
      ApiService.get(`/assessment_instance/${assessmentID}/security_flags`)
        .then((res) => {
          commit('SET_USER_RESULTS', res.data.data);
          resolve(res.data.data);
        })
        .catch((error) => {
          if (error.response && (error.response.status === 401 || error.response.status === 403 || error.response.status === 500)) {
            dispatch('noAuth');
          }
          reject(error);
        });
    }),
    getAssessments: ({ commit, dispatch }, { assessmentID }) => new Promise((resolve, reject) => {
      ApiService.get(`/assessments/${assessmentID}`)
        .then((res) => {
          const assessmentsArray = res.data.data;
          let recentAssessments = [];
          if (assessmentID !== '') {
            commit('SET_SELECTED_ASSESSMENT', assessmentsArray);
            resolve(res.data.data);
          } else {
            recentAssessments = assessmentsArray.slice(0, 5);
            commit('SET_ALL_ASSESSMENT_DATA', assessmentsArray);
            commit('SET_RECENT_ASSESSMENT_DATA', recentAssessments);
          }
          commit('UPDATE_AUTH_TOKEN', res.token);
          resolve(res.data);
        })
        .catch((error) => {
          if (error.response && (error.response.status === 401 || error.response.status === 403 || error.response.status === 500)) {
            dispatch('noAuth');
          }
          reject(error);
        });
    }),
    getAssessmentsByJobRole: ({ commit, dispatch }, { jobRole }) => new Promise((resolve, reject) => {
      ApiService.get(`/assessments?job_code=${jobRole}`)
        .then((res) => {
          if (res.data) {
            if (res.data.error) {
              reject(res.data.error);
            } else {
              commit('SET_ALL_ASSESSMENT_DATA', res.data.data);
              commit('UPDATE_AUTH_TOKEN', res.data.token);
              resolve(res.data.data);
            }
          }
        })
        .catch((error) => {
          if (error.response && (error.response.status === 401 || error.response.status === 403 || error.response.status === 500)) {
            dispatch('noAuth');
          }
          reject(error);
        });
    }),
    getAssessmentInstance: ({ dispatch, commit }, { assessmentID }) => new Promise((resolve, reject) => {
      ApiService.get(`/assessment_instance/${assessmentID}`)
        .then((res) => {
          commit('UPDATE_AUTH_TOKEN', res.data.token);
          resolve(res.data.data);
        })
        .catch((error) => {
          if (error.response && (error.response.status === 401 || error.response.status === 403 || error.response.status === 500)) {
            dispatch('noAuth');
          }
          reject(error);
        });
    }),
    createAssessment: ({ commit, dispatch }, params) => new Promise((resolve, reject) => {
      ApiService.post('/assessments/', params)
        .then((response) => {
          if (response.data) {
            if (response.data.error) {
              reject(response.data.error);
            } else if (response.data.data) {
              commit('SET_SELECTED_ASSESSMENT', response.data.data);
              resolve(response.data);
            } else {
              reject(response.data.error);
            }
          } else {
            reject(response.data.error);
          }
        })
        .catch((err) => {
          if (err && err.response && err.response.status === 403) {
            dispatch('noAuth');
          }
          reject(err);
        });
    }),
    updateAssessment: ({ dispatch }, { assessmentID, params }) => new Promise((resolve, reject) => {
      let putParams = {};
      putParams.name = params.name;
      putParams.status = params.status;
      if (params.security_features.length > 0) {
        putParams.security_features = params.security_features.map(obj => obj.id);
      } else {
        putParams.security_features = [];
      }
      putParams.time_limit = params.time_limit;
      putParams.deadline_date = params.deadline_date;
      if (params.job_Codes) {
        // eslint-disable-next-line no-param-reassign
        params.job_codes = params.job_Codes;
      }
      if (params.job_codes.length > 0) {
        putParams.job_codes = params.job_codes.map(obj => obj.id);
      } else {
        putParams.job_codes = [];
      }
      putParams = { assessment: putParams };
      ApiService.put(`/assessments/${assessmentID}`, putParams)
        .then(() => {
          resolve();
        })
        .catch((err) => {
          if (err && err.response && err.response.status === 403) {
            dispatch('noAuth');
          }
          reject(err);
        });
    }),
    deleteAssessment: ({ dispatch }, { assessmentID }) => new Promise((resolve, reject) => {
      ApiService.delete(`/assessments/${assessmentID}`)
        .then((response) => {
          if (response.status === 204) {
            resolve('SUCCESS');
          } else {
            reject(response.data.error);
          }
        })
        .catch((err) => {
          if (err && err.response && err.response.status === 403) {
            dispatch('noAuth');
          }
          reject(err);
        });
    }),
    createAssessmentInstance: ({ commit }, params) => new Promise((resolve, reject) => {
      ApiService.post('/assessment_instance/', params)
        .then((response) => {
          if (response.data) {
            if (response.data.error) {
              reject(response.data.error);
            } else if (response.data.data) {
              resolve(response.data.data);
            } else {
              reject(response.data.error);
            }
          } else {
            reject(response.data.error);
          }
        })
        .catch((err) => {
          if (err && err.response && err.response.status === 403) {
            commit('');
          }
          reject(err);
        });
    }),
    updateQuestions: ({ dispatch }, { assessmentID, questions, deletedQuestions }) => new Promise((resolve, reject) => {
      const putParams = [];
      if (deletedQuestions.length > 0) {
        for (let i = 0; i < deletedQuestions.length; i += 1) {
          ApiService.delete(`/questions/${deletedQuestions[i]}`);
        }
      }
      for (let i = 0; i < questions.length; i += 1) {
        const question = {};
        question.id = questions[i].id;
        question.text = questions[i].text;
        question.answers = questions[i].answers;
        question.sequence = questions[i].sequence;
        if (questions[i].text_varient) {
          question.text_varient = questions[i].text_varient;
        }
        putParams.push(question);
      }
      ApiService.put('/questions', { assessmentID, questions: putParams })
        .then(() => {
          resolve();
        })
        .catch((err) => {
          if (err && err.response && err.response.status === 403) {
            dispatch('noAuth');
          }
          reject(err);
        });
    }),
    getAssessmentQuestions: ({ commit }, { assessmentID }) => new Promise((resolve, reject) => {
      ApiService.get(`/assessment_instance/${assessmentID}/questions`)
        .then((response) => {
          if (response.data) {
            if (response.data.error) {
              reject(response.data.error);
            } else if (response.data.data) {
              commit('UPDATE_AUTH_TOKEN', response.data.token);
              resolve(response.data.data);
            } else {
              reject(response.data.error);
            }
          } else {
            reject(response.data.error);
          }
        })
        .catch((err) => {
          if (err && err.response && err.response.status === 403) {
            commit('');
          }
          reject(err);
        });
    }),
    submitAnswer: ({ commit, dispatch }, { assessmentID, params }) => new Promise((resolve, reject) => {
      ApiService.post(`/assessment_instance/${assessmentID}/answer_submissions`, params)
        .then((response) => {
          if (response.data.data) {
            commit('UPDATE_AUTH_TOKEN', response.data.token);
            resolve(response.data.data);
          } else if (response.error) {
            reject(response.error);
          } else {
            resolve();
          }
        })
        .catch((err) => {
          if (err && err.response && err.response.status === 403) {
            dispatch('noAuth');
          }
          reject(err);
        });
    }),
    securityQuestionResponse: ({ dispatch }, { assessmentID, answer }) => new Promise((resolve, reject) => {
      ApiService.post(`/assessment_instance/${assessmentID}/security_question_response`, { response: answer })
        .then((response) => {
          if (!response.error) {
            resolve();
          } else if (response.error) {
            reject(response.error);
          }
        })
        .catch((err) => {
          if (err && err.response && err.response.status === 403) {
            dispatch('noAuth');
          }
          reject(err);
        });
    }),
    submitAssessment: ({ dispatch }, { assessmentID, params }) => new Promise((resolve, reject) => {
      ApiService.post(`/assessment_instance/${assessmentID}/results`, params)
        .then((response) => {
          if (!response.error) {
            resolve();
          } else if (response.error) {
            reject(response.error);
          }
        })
        .catch((err) => {
          if (err && err.response && err.response.status === 403) {
            dispatch('noAuth');
          }
          reject(err);
        });
    }),
    sourceImage: ({ commit, dispatch }, { assessmentID, file }) => new Promise((resolve, reject) => {
      ApiService.post('/source_images', { assessment_instance_id: assessmentID })
        .then((res) => {
          if (res.data) {
            if (res.data.error) {
              reject(res.data.error);
            } else if (res.data.data) {
              const url = res.data.data;
              dispatch('uploadImage', { url, file })
                .then(() => {
                  commit('UPDATE_AUTH_TOKEN', res.data.token);
                  resolve();
                });
            } else {
              reject(res.error);
            }
          } else {
            reject(res.error);
          }
        })
        .catch((err) => {
          if (err && err.response && err.response.status === 403) {
            commit('');
          }
          reject(err);
        });
    }),
    currentImage: ({ commit, dispatch }, { assessmentID, file }) => new Promise((resolve, reject) => {
      ApiService.post('/current_images', { assessment_instance_id: assessmentID })
        .then((res) => {
          if (res.data) {
            if (res.data.error) {
              reject(res.data.error);
            } else if (res.data.data) {
              const url = res.data.data;
              dispatch('uploadImage', { url, file })
                .then(() => {
                  resolve();
                });
            } else {
              reject(res.error);
            }
          } else {
            reject(res.error);
          }
        })
        .catch((err) => {
          if (err && err.response && err.response.status === 403) {
            commit('');
          }
          reject(err);
        });
    }),
    uploadImage: ({ commit }, { url, file }) => new Promise((resolve, reject) => {
      S3Api(url, file)
        .then(() => {
          resolve();
        })
        .catch((err) => {
          if (err && err.response && err.response.status === 403) {
            commit('');
          }
          reject(err);
        });
    }),
    imageComparisons: ({ commit }, { assessmentID }) => new Promise((resolve, reject) => {
      ApiService.post('image_comparisons', { assessment_instance_id: assessmentID })
        .then((res) => {
          commit('UPDATE_AUTH_TOKEN', res.data.token);
          resolve();
        })
        .catch((err) => {
          if (err && err.response && err.response.status === 403) {
            commit('');
          }
          reject(err);
        });
    }),
    startAssessmentInstance: ({ dispatch }, { assessmentID }) => new Promise((resolve, reject) => {
      ApiService.post(`/assessment_instance/${assessmentID}/start`)
        .then(() => {
          resolve();
        })
        .catch((err) => {
          if (err && err.response && err.response.status === 403) {
            dispatch('noAuth');
          }
          reject(err);
        });
    }),
    receiveAuthCode: ({ commit }, { authType, assessmentID }) => new Promise((resolve, reject) => {
      ApiService.post('/auth_code', { device: authType, assessment_instance_id: assessmentID })
        .then((res) => {
          commit('UPDATE_AUTH_TOKEN', res.data.token);
          resolve();
        })
        .catch((err) => {
          if (err && err.response && err.response.status === 403) {
            commit('');
          }
          reject(err);
        });
    }),
    submitAuthCode: ({ commit }, { code, assessmentID }) => new Promise((resolve, reject) => {
      ApiService.post('/auth_code/response', { code, assessment_instance_id: assessmentID })
        .then((res) => {
          commit('UPDATE_AUTH_TOKEN', res.data.token);
          resolve();
        })
        .catch((err) => {
          if (err && err.response && err.response.status === 403) {
            commit('');
          }
          reject(err);
        });
    }),
    allowRetake: ({ commit }, { assessmentID, userId }) => new Promise((resolve, reject) => {
      ApiService.post('/retake', { assessment_id: assessmentID, user_id: userId })
        .then(() => {
          resolve();
        })
        .catch((err) => {
          if (err && err.response && err.response.status === 403) {
            commit('');
          }
          reject(err);
        });
    }),
    downloadResult: ({ dispatch }, { resultID }) => new Promise((resolve, reject) => {
      ApiService.post(`/results/${resultID}/file`)
        .then((response) => {
          if (response) {
            resolve(response);
          } else {
            reject(response.data.error);
          }
        })
        .catch((err) => {
          if (err && err.response && err.response.status === 403) {
            dispatch('refresh');
          }
          reject(err);
        });
    }),
    noAuth({ commit }) {
      commit('PURGE_AUTH');
      // eslint-disable-next-line no-restricted-globals
      const siteUrl = `${location.protocol}//${location.host}${location.pathname}`;
      window.location = `${process.env.VUE_APP_CALLBACK_URL}/?APP_ID=LEGACY&callback=${siteUrl}`;
    },
  },
  modules: {
  },
  getters: {
    loginInfo: state => state.loginInfo,
    user: state => state.user,
    testerSelected: state => state.testerSelected,
  },
});
