<template>
  <div>
    <div style="display: flex; flex-direction: column; justify-content: center; width: 400px; align-items: center; margin: 10px auto;">
      <div>
      <span>Username</span>
      <input v-model="userName" />
      </div>
      <div>
        <span>Password</span>
        <input v-model="password" />
      </div>
      <div style="display: flex; margin-top: 20px;">
      <button style="margin-right: 15px;" @click="login()">Login</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'login',
  data() {
    return {
      userName: null,
      password: null,
      loginInfo: null,
    };
  },
  methods: {
    login() {
      const params = {
        '24g_id': this.userName,
        // eslint-disable-next-line quote-props
        'password': this.password,
      };
      const loader = this.$loading.show();
      localStorage.setItem('auth-type', 'custom');
      this.$store.dispatch('getCustomLogin', params)
        .then((res) => {
          this.loginInfo = res;
          loader.hide();
          if (res.data.app_role_id === 1) {
            this.getUser();
          } else {
            this.$router.push({ name: 'dashboard' });
          }
        })
        .catch(() => {
          loader.hide();
        });
    },
    getUser() {
      const loader = this.$loading.show();
      this.$store.dispatch('getUser', { loginInfo: this.loginInfo })
        .then((res) => {
          if (res && res.email !== '' && res.email !== null) {
            loader.hide();
            this.$router.push({ name: 'testerDashboard' });
          } else {
            loader.hide();
            this.$router.push({ name: 'welcome' });
          }
        });
    },
    goToDashboard() {
      this.$router.push({
        name: 'dashboard',
      });
    },
  },
};
</script>
