<template>
  <div class="header">
    <div>{{pageHeader}}</div>
    <div class="header-right">
      <div style="font-size: 14px; align-self: center">
        Welcome,
        <span style="color: #00B0F0; font-size: 14px; font-family: VWHead Bold">{{ user.first_name }} {{user.last_name}}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
  },
  data() {
    return {
    };
  },
  computed: {
    pageHeader() {
      if (this.$route.name && this.adminMenu) {
        const index = this.adminMenu.findIndex(item => item.key === this.$route.name);
        if (index !== -1) {
          return this.adminMenu[index].name;
        }
      }
      if (this.$route.name === 'create') {
        return 'Create Assessment';
      }
      if (this.$route.name === 'edit') {
        return 'Edit Assessment';
      }
      if (this.$route.name === 'duplicate') {
        return 'Duplicate Assessment';
      }
      if (this.$route.name === 'success') {
        return 'Assessments';
      }
      return '';
    },
    adminMenu() {
      return this.$store.state.adminMenu;
    },
    user() {
      if (this.$store.state.user) {
        return this.$store.state.user;
      }
      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
.header-right{
    display: flex;
}
</style>
