<template>
  <transition name="modal-fade">
    <div class="modal-bg">
      <section
        class="modal"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
      >
        <header class="modal-header" id="modalTitle" v-if="hasHeaderSlot">
          <slot name="header"></slot>
        </header>
        <div class="modal-body" id="modalDescription">
          <slot name="body"></slot>
        </div>
         <div class="modal-body overflow" id="modalDescription">
          <slot name="body-overflow"></slot>
        </div>
        <footer class="modal-footer">
          <slot name="footer">
          </slot>
        </footer>
      </section>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'modal',
  computed: {
    hasHeaderSlot() {
      return !!this.$slots.header;
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-fade-enter,
.modal-fade-leave-active {
  opacity: 0;
}
.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s ease;
}
.modal-bg {
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;

  .modal {
    background-color: #fff;
    box-shadow: 2px 2px 20px 1px rgba(0, 0, 0, 0.5);
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    padding: 33px;
    overflow: visible;

    .modal-header,
    .modal-footer {
      display: flex;
    }

    .modal-header {
      padding-bottom: 25px;
      justify-content: space-between;
    }

    .modal-footer {
      justify-content: center;
      padding-top: 30px;
      @media only screen and (max-width: 700px) {
      width: 300px;
      }
      @media only screen and (max-width: 400px) {
      width: 250px;
      }
    }

    .modal-body {
      position: relative;
    }
  }
}
.overflow {
  overflow: auto;
}
</style>
