import axios from 'axios';
import VueAxios from 'vue-axios';
import Vue from 'vue';

const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = process.env.VUE_APP_API_URL;
    Vue.axios.defaults.headers.common['Content-Type'] = 'application/json';
    Vue.axios.defaults.headers.common.auth_type = 'vw';
    Vue.axios.defaults.headers.common.Expires = '-1';
    Vue.axios.defaults.headers.common['Cache-Control'] = 'no-store, no-cache, must-revalidate';
    Vue.axios.defaults.headers.common.Pragma = 'no-cache';
    this.setToken();
  },
  setToken() {
    const token = localStorage.getItem('user-token');
    if (token) {
      Vue.axios.defaults.headers.common.Authorization = token;
    } else delete Vue.axios.defaults.headers.common.Authorization;
  },
  get(resource, params) {
    return Vue.axios.get(`${resource}`, { params });
  },

  post(resource, params, queryParams) {
    return Vue.axios.post(`${resource}`, params, queryParams);
  },

  patch(resource, params) {
    return Vue.axios.patch(`${resource}`, params);
  },

  put(resource, params) {
    return Vue.axios.put(`${resource}`, params);
  },

  delete(resource, params) {
    return Vue.axios.delete(`${resource}`, params);
  },
};

export default ApiService;
