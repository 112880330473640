<template>
  <div class="nav-bar">
    <div class="nav-bar-blue">
      <div class="top-container">
        <logo class="logo" />
        <div class="label">
          <span style="font-family: VWHead Bold">Secure </span>
          <span>Testing</span>
        </div>
      </div>
      <div
        v-for="(item, index) in adminMenu"
        :key="item.key"
        :class="{'menu-container': true, active: activeItem ? item.key === activeItem.key : false}"
        @click="navigate(item.key, index)"
      >
      <div style="width: 20px; margin-right: 20px;">
        <img
          :src="activeItem ? item.key === activeItem.key ? item.activeIcon : item.icon : item.icon"
          :class="{'menu-icon': true, active: activeItem ? item.key === activeItem.key : false}"
        />
        </div>
        <span>{{item.name}}</span>
      </div>
    </div>
    <div class='bottom-container'>
      <div class="create" :style="activeItem === 'create' ? 'font-family: VWText Bold' : 'font-family: VWText Light'" @click="navigate('create')">Create Assessment</div>
      <a href="https://vwgoalogin.track360.com/logout.php" style="cursor: pointer; text-decoration: none; color: black; font-family: VWText Light;">Log Out</a>
    </div>
  </div>
</template>

<script>
import logo from '@/assets/icons/logo.svg';


export default {
  name: 'navbardesktop',
  components: {
    logo,
  },
  data() {
    return {
    };
  },
  methods: {
    removeSelectedTester() {
      this.$store.commit('SET_SELECTED_TESTER', null);
    },
    navigate(key, index) {
      this.removeSelectedTester();
      if (index && this.$route.name !== key) {
        this.$router.push({
          name: key,
        });
      } else if (this.$route.name !== key) {
        this.$router.push({
          name: key,
        });
      }
    },
  },
  computed: {
    adminMenu() {
      return this.$store.state.adminMenu;
    },
    activeItem() {
      if (this.$route && this.$route.name !== 'create') {
        const index = this.adminMenu.findIndex(item => item.key === this.$route.name);
        return this.adminMenu[index];
      }
      if (this.$route.name === 'dashboard') {
        return this.adminMenu[0];
      }
      if (this.$route.name === 'create') {
        return 'create';
      }
      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-bar {
  width: 350px;
  background-color: #dfe4e8;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 3px 6px #00000029;
  color: #FFFFFF;

  .nav-bar-blue {
    background-color: #001e50;
    padding-bottom: 10px;
  }

  .top-container {
    display: flex;
    flex-direction: column;
    margin: 30px 0;

    .label {
      text-align: center;
      font-size: 24px;
    }
  }
}

.menu-icon {
  margin-right: 15px;
  width: 100%;
  height: 20px;
  &.active {
    width: 100%;
  }
}

.menu-container {
  display: flex;
  padding: 15px 0px 15px 30px;
  font-size: 18px;
  cursor: pointer;
  &.active {
    background-color: rgba(0,176,240, .5);
    font-family: "VWHead Bold";
  }
}

.bottom-container{
  display: flex;
  flex-direction: column;
  height: 100%;
  color: black;
  padding: 30px 0 0 30px;
}

.create {
  margin-bottom: 30px;
  cursor: pointer;
}
</style>
