import Vue from 'vue';
import './registerServiceWorker';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import VTooltip from 'v-tooltip';
import Notifications from 'vue-notification';
import VCalendar from 'v-calendar';
import VueProgressBar from 'vue-progressbar';
import vClickOutside from 'v-click-outside';
import DateFilter from './utils/date.filter';
import PhoneFilter from './utils/phone.filter';
import TimeFilter from './utils/time.filter';
import EmailHideFilter from './utils/emailHide.filter';
import PhoneHideFilter from './utils/phoneHide.filter';
import router from './router';
import store from './store';
import App from './App.vue';
import ApiService from './services/Api';


Vue.config.productionTip = false;

const options = {
  thickness: '20px',
  position: 'relative',
};

Vue.filter('date', DateFilter);
Vue.filter('phone', PhoneFilter);
Vue.filter('phoneHide', PhoneHideFilter);
Vue.filter('emailHide', EmailHideFilter);
Vue.filter('time', TimeFilter);

Vue.use(Loading);
Vue.use(Notifications);
Vue.use(VTooltip);
Vue.use(vClickOutside);
Vue.use(require('vue-moment'));
require('md-gum-polyfill');


Vue.use(VCalendar);
Vue.use(VueProgressBar, options);
ApiService.init();

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
